import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner } from '../components/Section'
import { LetastArticlesBgWhite, CustomTags, SectionBeforefterBg, PageTitle, PageTitleWrapper, CardImg, TraingleShape, CaseStudsyContent, TagWrapper, CaseStudyWrapper} from "../components/CaseStudyStyle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BgBefore from '../images/case-study-before.png'
import BgAfter from '../images/case-study-after.png'
import CaseStudyFeatures from "../components/CaseStudyFeatures"

function caseStudyPage() {
  return (
    <Layout pageName="case-study-detail">
        <SEO title="City Transfer and Storage – High Point, NC" description="City Transfer and Storage – High Point, NC" />
        <SectionBeforefterBg pt="156px" pb="100px" xpt="80px" xpb="60px" mpb="40px" bgColor="#F8F8F8" bgBefore={`url(${BgBefore})`} bgAfter={`url(${BgAfter})`}>
        <TraingleShape />
        <div className="container">
            <BreadCrumb>
                <Link to="/">Home</Link> <Link to="/case-studies">Case Studies</Link><Link to="/#">City Transfer and Storage – High Point, NC</Link>
            </BreadCrumb>
            <PageTitleWrapper>
                <PageTitle className="h2">City Transfer and Storage – High Point, NC</PageTitle>
                <Tags to="/">New VOIP Phone System & SIP Lines installation</Tags>
                <Tags to="/">Fiber Cable installation</Tags>
            </PageTitleWrapper>
            <CaseStudyWrapper>
                <CaseStudsyContent>
                    <CardImg>
                        <StaticImage
                        src="../images/case.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                        />
                    </CardImg>
                    <p>The legacy phone system being used by City Transfer and Storage was no longer supported. They came to WYN looking to upgrade their phone system to a sustainable modern technology solution which would maximize their return on investment well into the future. The chosen technology allows associates to connect their phone, PC, and smartphone for greater onsite and offsite productivity.</p>
                </CaseStudsyContent>
            </CaseStudyWrapper>
        </div>
        </SectionBeforefterBg>
        <Section pt="0" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
            <div className="container">
                <CaseStudyWrapper>
                    <MarkdownContentWrapper>
                    <MarkdownContentWrapperInner>
                        <MarkdownContent>
                            <h2>Services Delivered</h2>
                            <TagWrapper>
                                <CustomTags>Installation of new VOIP telephone system with new hardware and SIP lines; running fiber optic cabling from point of demarcation to the building</CustomTags>
                            </TagWrapper>
                            <p>Site surveys were conducted in multiple service locations to determine the most efficient system for connecting divisions and providing precise, uninterrupted communications across the enterprise which also prioritized the ability to deliver a sustained high level of customer service. WYN then implemented an appropriate VOIP solution for the client.</p>
                            <ul>
                                <li>With this technology solution, communication is never interrupted.</li>
                                <li>In the event of a power grid failure, calls are automatically forwarded to cellular devices so that no call goes unanswered.</li>
                                <li>In-house calls can be completed by simply dialing the proper extension, with no need for tedious 10-digit dialing.</li>
                                <li>Voice Over Internet Protocol calling is much more cost-effective, saving the client potentially thousands of dollars per year.</li>
                                <li>New customizable phone hardware includes many add-ons which offer increased functionality, including the  ability to utilize plug-and-play phones from essentially any location.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Problem Statements</h2>
                            <p>The legacy phone system being used by City Transfer and Storage was at its end of life, and no longer supported. Any failed equipment could not be replaced, call features were limited, the cost to maintain the old system was out of budget, and there was no ability to use equipment remotely.</p>
                            <ul>
                                <li>Limited call features</li>
                                <li>No communications failsafe in the event of a power grid failure</li>
                                <li>Without the ability to call in-house locations by extension, multiple land lines had to be retained at additional cost</li>
                                <li>No find-me-follow-me capabilities, since no mobile application was available with legacy system</li>
                                <li>No desktop apps available, and no ability to forward calls to PCs or mobile devices</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Wyn Approach</h2>
                            <p>The WYN approach to resolving the client’s challenges began with conducting site surveys at each location to determine the best course of action concerning VOIP technology implementation in a manner that would meet the client’s budget. It was also necessary to determine the number of employees who would need phone system access, and then grant each employee the proper permissions.</p>
                            <ul>
                                <li>Determine the total number of employees who would need phone permissions.</li>
                                <li>Determine the number of phones needed per location.</li>
                                <li>Determine the amount of bandwidth needed to provide the needed level of reliable communication.</li>
                                <li>Assess current topology of existing switches & routers, and update as needed.</li>
                                <li>Updated cabling would need to be installed in certain locations.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Challenges</h2>
                            <p>The challenges were many. The legacy system in place didn’t allow for remote work, and had no ability to schedule video meetings directly from the phone. We also had to determine the existing IT equipment’s capability to support a new system, be able to install new equipment without any resulting business down time, and stay within the client’s budget.  </p>
                            <ul>
                                <li>Switches were replaced with newer technology to allow for much faster data transmission</li>
                                <li>Replaced existing POTS lines with a much better SIP lines all-in-one solution</li>
                                <li>New system allows client associates to work remotely without any risk of missing calls</li>
                                <li>System allows users to schedule video conference meetings right from their phone or PC</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Benefits</h2>
                            <p>With this VOIP system in place, the client now has many more valuable tools and beneficial features at their disposal, including greater calling efficiency, site-to-site extension dialing, remote work connectivity, call forwarding to smartphones, video conferencing, integrated text messaging, and more. The integration of SIP lines and fiber optic cabling should also significantly lower their monthly phone system costs.</p>
                            <ul>
                                <li>No more missed calls</li>
                                <li>Remote work calls possible under the banner of the official business phone number</li>
                                <li>Ability to work remotely at will, even during public health crises or other circumstances</li>
                                <li>Drastically lowered monthly expenses for business communications</li>
                                <li>Minimal upfront cost with great long-term ROI</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <CaseStudyFeatures />
                        </MarkdownContent>
                    </MarkdownContentWrapperInner>
                    </MarkdownContentWrapper>
                </CaseStudyWrapper>
            </div>
        </Section>
        <LetastArticlesBgWhite>
         <LetastArticles />
        </LetastArticlesBgWhite>
    </Layout>
  )
}
export default caseStudyPage